import { Component, OnInit, } from '@angular/core';
import { TranslateServiceLocal } from '../../translate.service';

@Component({
    selector: 'app-404',
    templateUrl: '../../../404.html',
    styleUrls: ['./404.component.scss'],
    providers: [TranslateServiceLocal]
})
export class NotFoundComponent implements OnInit {

    constructor(private translateService: TranslateServiceLocal) {}

    ngOnInit() {}

    changeRedirect(redirectTo) {
        this.translateService.changeRedirect(redirectTo);
    }

}

