import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {

    constructor(private http: HttpClient) {}

    changePassword(data): Observable<any> {
      return this.http.post(environment.server + 'changePassword', data).pipe(map(res => res));
    }
}
