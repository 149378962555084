import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private crypto: CryptoService) {}

  public get currentUserValue() {
    if (!localStorage.getItem('token')) {
      return null;
    }
    return this.crypto.get(environment.secretKey, localStorage.getItem('token'));
  }

  public login(data): Observable<any> {
    return this.http.post<any>(environment.server + 'users/login', data).pipe(map(res => {
      if (res) {
        const encrypt = this.crypto.set(environment.secretKey, res);
        localStorage.setItem('token', encrypt + '');
      }
      return res;
    }));
  }

  public logout() {
    window.localStorage.removeItem('token');
    window.location.href = '/login';
  }

  getGeneralHeaders() {
    if (!this.currentUserValue) {
      return {
        headers: new HttpHeaders()
      };
    }
    return {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.currentUserValue.token)
    };
  }

  public sendEmail(data): Observable<any> {
    return this.http.post(environment.server + 'sendEmail', data).pipe(map(res => res));
  }
}
