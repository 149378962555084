import { Component, OnInit } from '@angular/core';
import { TranslateServiceLocal } from '../../../translate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignUpService } from './sign-up.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [TranslateServiceLocal, SignUpService]
})
export class SignUpComponent implements OnInit {

  registerForm: FormGroup;
  patternEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
  patternPhone = new RegExp('^[0-9]{6,20}$');
  submitted = false;
  countries;
  confirmSendData = false;
  keyword = 'name';
  countryIndicative = '';

  constructor(
    private translateService: TranslateServiceLocal,
    private formBuilder: FormBuilder,
    private signUpService: SignUpService,
  ) { }

  ngOnInit() {
    this.getCountries();
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.pattern(this.patternEmail)]],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      country: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(this.patternPhone)]],
    });
  }

  getCountries () {
    this.signUpService.getCountries().subscribe(res => {
      this.countries = res;
    });
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }

  selectEvent(e) {
    this.patchCountry(e);
  }

  countryValid(countrySearch) {
    countrySearch = countrySearch.charAt(0).toUpperCase() + countrySearch.slice(1)
    const country: any = this.existCountry(countrySearch);
    if (country) {
      this.patchCountry(country);
    } else {
      this.countryIndicative = '';
      document.getElementById('countryError').style.display = 'block';
    }
  }

  patchCountry(country) {
    this.registerForm.patchValue({
      country: country.alpha2Code
    });
    this.countryIndicative = '+' + country.callingCodes[0];
    document.getElementById('countryError').style.display = 'none';
  }

  existCountry(countrySearch) {
    let exist = false;
    if (this.countries) {
      exist = this.countries.find(country => country.name === countrySearch);
    }
    return exist;
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      if (this.f.country.errors) {
        document.getElementById('countryError').style.display = 'block';
      }
      return;
    }

    this.registerForm.patchValue({
      phone: this.countryIndicative + this.registerForm.value.phone
    });

    this.confirmSendData = true;
    document.getElementById('signUpContainer').style.display = 'none';

    this.signUpService.createUser(this.registerForm.value).subscribe(res => {
      document.getElementById('successSignUp').style.display = 'block';
      this.confirmSendData = false;
    }, err => {
      document.getElementById('errorSignUp').style.display = 'block';
      this.confirmSendData = false;
    });


  }

}
