import { Component, OnInit, Input } from '@angular/core';
import { TranslateServiceLocal } from '../../../translate.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [ TranslateServiceLocal]
})
export class MenuComponent implements OnInit {

  @Input() menuActive: string;
  settingDown = false;

  constructor(
    private translateService: TranslateServiceLocal,
  ) { }

  ngOnInit() {
    if (this.menuActive === 'Menu3') {
      this.settingDown = true;
    }
    document.getElementById(this.menuActive).classList.add('active');
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }

  settingsClick() {
    if (document.getElementsByClassName('fa-chevron-right').length !== 0 ) {
      document.getElementById('rowSetting').classList.remove('fa-chevron-right');
      document.getElementById('rowSetting').classList.add('fa-chevron-down');
      this.settingDown = true;
    } else {
      document.getElementById('rowSetting').classList.remove('fa-chevron-down');
      document.getElementById('rowSetting').classList.add('fa-chevron-right');
      this.settingDown = false;
    }
  }
}
