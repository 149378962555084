import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { TranslateServiceLocal } from '../translate.service';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [],
    providers: [TranslateServiceLocal]
})

export class ComponentsComponent implements OnInit, OnDestroy {
    screenWidth = screen.width;
    @HostListener('window:resize') onResize() {
        this.screenWidth = screen.width;
    }
    isflickity() {
        if (this.screenWidth < 1000) {
            return '{ "lazyLoad": true }';
        }
        return null;
    }

    constructor(private translateService: TranslateServiceLocal) {}

    ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }
    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }
}
