import { Component, OnInit, } from '@angular/core';
import { TranslateServiceLocal } from '../../translate.service';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    thisYear  = new Date().getFullYear();

    constructor(private translateService: TranslateServiceLocal) {}

    ngOnInit() {}

    changeRedirect(redirectTo) {
        this.translateService.changeRedirect(redirectTo);
    }
}

