import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecoverPasswordService } from './recover-password.service';
import { TranslateServiceLocal } from '../../../translate.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  providers: [RecoverPasswordService, TranslateServiceLocal]
})
export class RecoverPasswordComponent implements OnInit {

  recoverPassForm: FormGroup;
  confirmSendData = false;
  submitted = false;
  patternPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,}$');

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateServiceLocal,
    private recoverPasswordService: RecoverPasswordService
  ) {
    if (!this.tokenCorrect()) {
      this.changeRedirect('/login');
    }
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }

  ngOnInit() {
    this.recoverPassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.patternPassword)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(this.patternPassword)]]
    }, {validator: this.checkPasswords });
  }

  get f() { return this.recoverPassForm.controls; }

  checkPasswords(form: FormGroup) {
      const pass = form.controls.password.value;
      const confirmPass = form.controls.confirmPassword.value;
      return pass === confirmPass ? null : { notSame: true };
  }

  tokenCorrect() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    if (token) {
      const parts = token.split('.');
      return parts.length === 3 && token.substr(0, 2) === 'ey' && token.length > 50;
    }
    return false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.recoverPassForm.invalid) {
      return;
    }
    this.confirmSendData = true;
    document.getElementById('setPasswordContainer').style.display = 'none';

    this.recoverPasswordService.changePassword(this.recoverPassForm.value).subscribe(() => {
      document.getElementById('setPasswordSuccess').style.display = 'block';
      this.confirmSendData = false;
    }, err => {
      document.getElementById('errorSetPassword').style.display = 'block';
      this.confirmSendData = false;
    });
  }

}
