import { Component, OnInit, ViewChild } from '@angular/core';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from './shared/navbar/navbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor() {}

    ngOnInit() { }
}
