import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { CryptoService } from './developer/login/crypto.service';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { ContactsComponent } from './contacts/contacts.component';
import { NotFoundComponent } from './404/404.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ComponentsComponent } from './components.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { NavbarComponent } from '../shared/navbar/navbar.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './developer/login/login.component';
import { SignUpComponent } from './developer/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './developer/recover-password/recover-password.component';
import { DemandApiComponent } from './developer/demand-api/demand-api.component';
import { OverviewComponent } from './developer/overview/overview.component';
import { ListDemandApiComponent } from './developer/list-demand-api/list-demand-api.component';
import { MenuComponent } from './developer/menu/menu.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        HttpClientModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        ComponentsComponent,
        ContactsComponent,
        PortfolioComponent,
        NotFoundComponent,
        NavbarComponent,
        FooterComponent,
        LoginComponent,
        SignUpComponent,
        RecoverPasswordComponent,
        DemandApiComponent,
        OverviewComponent,
        ListDemandApiComponent,
        MenuComponent,
    ],
    providers: [CryptoService],
    exports: [ ComponentsComponent ]
})
export class ComponentsModule { }
