import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DemandAPIService {

  headers;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.headers = this.loginService.getGeneralHeaders();
  }

  public generateToken(data): Observable<any> {
    return this.http.post(environment.server + 'generateToken', data, this.headers).pipe(map(res => res));
  }
}
