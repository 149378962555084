import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import {AuthGuard} from './components/developer/auth.guard';

import { ComponentsComponent } from './components/components.component';

import { ContactsComponent } from './components/contacts/contacts.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { NotFoundComponent } from './components/404/404.component';

// --------------------------------- Developer --------------------------------------

import { LoginComponent } from './components/developer/login/login.component';
import { SignUpComponent } from './components/developer/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './components/developer/recover-password/recover-password.component';
import { DemandApiComponent } from './components/developer/demand-api/demand-api.component';
import { OverviewComponent } from './components/developer/overview/overview.component';
import { ListDemandApiComponent } from './components/developer/list-demand-api/list-demand-api.component';

const routes: Routes = [
  { path: '', component: ComponentsComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'portafolio', component: PortfolioComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard] },
  {
    path: 'products/developers-products/demand_api/setup',
    component: DemandApiComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products/developers-products/demand_api/list',
    component: ListDemandApiComponent,
    canActivate: [AuthGuard]
  },
  { path: 'setPassword/:token', component: RecoverPasswordComponent },
  { path: ':lang/404', component: NotFoundComponent, pathMatch: 'full' },
  { path: ':lang/contact', component: ContactsComponent, pathMatch: 'full' },
  {
    path: ':lang/portafolio',
    component: PortfolioComponent,
    pathMatch: 'full'
  },
  { path: ':lang/login', component: LoginComponent, pathMatch: 'full' },
  { path: ':lang/signup', component: SignUpComponent, pathMatch: 'full' },
  {
    path: ':lang/products/developers-products/demand_api/setup',
    component: DemandApiComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: ':lang/products/developers-products/demand_api/list',
    component: ListDemandApiComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: ':lang/overview',
    component: OverviewComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: ':lang/setPassword/:token',
    component: RecoverPasswordComponent,
    pathMatch: 'full'
  },
  { path: ':lang', component: ComponentsComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule {}
