import { Component, OnInit, ElementRef } from '@angular/core';
import { Location} from '@angular/common';
import { TranslateServiceLocal } from '../../translate.service';
import { Router } from '@angular/router';
import { LoginService } from '../../components/developer/login/login.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [LoginService]
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    name_user;

    constructor(
        public location: Location,
        private element: ElementRef,
        private router: Router,
        private loginService: LoginService,
        private translateService: TranslateServiceLocal) {
            this.sidebarVisible = false;
    }

    ngOnInit() {
        const user = this.loginService.currentUserValue;
        if (user) {
            this.name_user = user.username;
        }
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function() {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isDocumentation() {
        const titlee = this.location.prepareExternalUrl(this.location.path());
        if ( titlee === '/documentation' ) {
            return true;
        } else {
            return false;
        }
    }

    switchLanguage(lang) {
        let positionUrl = 0;
        if (this.translateService.urlHasLang()) {
            positionUrl = this.translateService.getPositionUrl(this.router.url, '/', 2);
        }
        const url = (this.router.url).substring(positionUrl + 1, this.router.url.length);
        if (url.length > 0) {
            this.router.navigate([lang + '/' + url]);
        } else {
            this.router.navigate(
                [lang]
            );
        }
    }

    logout () {
        this.loginService.logout();
    }

    changeRedirect(redirectTo) {
        this.translateService.changeRedirect(redirectTo);
    }
}
