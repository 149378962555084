import { Component, OnInit } from '@angular/core';
import { TranslateServiceLocal } from '../../../translate.service';

@Component({
  selector: 'app-list-demand-api',
  templateUrl: './list-demand-api.component.html',
  styleUrls: ['./list-demand-api.component.scss'],
  providers: [ TranslateServiceLocal]
})
export class ListDemandApiComponent implements OnInit {

  menu = 'Menu2';

  constructor(
    private translateService: TranslateServiceLocal,
  ) { }

  ngOnInit() {
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }
}
