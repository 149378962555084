import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient) {}

  getCountries(): Observable<any> {
    return this.http.get('https://restcountries.eu/rest/v2/all').pipe(map(res => res));
  }

  createUser(data): Observable<any> {
    return this.http.post(environment.server + 'users', data).pipe(map(res => res));
  }
}
