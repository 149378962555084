import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceLocal {

  constructor(private translate: TranslateService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe( params => {
        if (params.lang === undefined) {
          translate.setDefaultLang('es');
        } else {
          if (params.lang === 'es' || params.lang === 'en') {
            translate.setDefaultLang(params.lang);
          } else {
            window.location.href = '/404';
          }
        }
    });
  }

  getPositionUrl(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  urlHasLang() {
    let retorno = false;
    this.route.params.subscribe( params => {
      if (params.lang !== undefined) {
        retorno = true;
      }
    });
    return retorno;
  }

  changeRedirect(redirectTo) {
    if (this.urlHasLang()) {
        const positionUrl = this.getPositionUrl(this.router.url, '/', 2);
        window.location.href = this.router.url.substring(0, positionUrl) + redirectTo
    } else {
        window.location.href = redirectTo
    }
  }

  public switchLanguage(lang) {
    this.translate.use(lang);
  }
}
