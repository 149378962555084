import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateServiceLocal } from '../../../translate.service';
import { DemandAPIService } from './demand-api.service';

@Component({
  selector: 'app-demand-api',
  templateUrl: './demand-api.component.html',
  styleUrls: ['./demand-api.component.scss'],
  providers: [ TranslateServiceLocal, DemandAPIService]
})
export class DemandApiComponent implements OnInit {

  demandAPIForm: FormGroup;
  submitted = false;
  confirmSendData = false;
  app_id = '';
  app_secret = '';
  settingDown = false;
  menu = 'Menu2';

  constructor(
    private translateService: TranslateServiceLocal,
    private formBuilder: FormBuilder,
    private demandAPIService: DemandAPIService
  ) { }

  ngOnInit() {
    this.demandAPIForm = this.formBuilder.group({
      app_name: ['', [Validators.required]],
      url: ['', [Validators.required]],
    });
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }

  get f() { return this.demandAPIForm.controls; }

  onSubmit() {

    this.submitted = true;
    if (this.demandAPIForm.invalid) {
      return;
    }

    this.confirmSendData = true;
    document.getElementById('demandAPIContainer').style.display = 'none';

    this.demandAPIService.generateToken(this.demandAPIForm.value).subscribe(res => {
      this.app_id = res.app_id;
      this.app_secret = res.app_secret;
      document.getElementById('demandAPISuccess').style.display = 'block';
      this.confirmSendData = false;
    }, err => {
      document.getElementById('errordemandAPI').style.display = 'block';
      this.confirmSendData = false;
    });
  }

}
