import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateServiceLocal } from '../../translate.service';
@Component({
  selector: 'app-porfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  providers: [TranslateServiceLocal]
})
export class PortfolioComponent implements OnInit {
    screenWidth = screen.width;
    @HostListener('window:resize') onResize() {
      this.screenWidth = screen.width;
    }
    isflickity() {
      if (this.screenWidth < 1000) {
          return '{ "lazyLoad": true }';
      }
      return null;
    }

    constructor(private translateService: TranslateServiceLocal) {}

    ngOnInit() {}

}
