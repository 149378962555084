import { Component, OnInit } from '@angular/core';
import { TranslateServiceLocal } from '../../../translate.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [TranslateServiceLocal, LoginService]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  patternPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  patternEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
  submitted = false;
  forgotPasswordMode = false;
  confirmSendData = false;

  constructor(
    private translateService: TranslateServiceLocal,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.patternEmail)]],
      password: ['', [Validators.required, Validators.pattern(this.patternPassword)]],
    });
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }

  get f() { return this.loginForm.controls; }

  forgotPasswordClick() {
    this.forgotPasswordMode = true;
    this.loginForm.removeControl('password');
  }

  back() {
    this.forgotPasswordMode = false;
    this.loginForm.addControl('password', new FormControl('', [Validators.required, Validators.pattern(this.patternPassword)]));
  }

  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.confirmSendData = true;
    document.getElementById('loginContainer').style.display = 'none';

    if (this.forgotPasswordMode) {
      this.loginService.sendEmail(this.loginForm.value).subscribe(() => {
        document.getElementById('emailSendSuccess').style.display = 'block';
        this.confirmSendData = false;
      }, err => {
        document.getElementById('errorLogin').style.display = 'block';
        this.confirmSendData = false;
      });
    } else {
      this.loginService.login(this.loginForm.value).subscribe(res => {
        window.location.href = '/overview';
        this.confirmSendData = false;
      }, err => {
        document.getElementById('errorLogin').style.display = 'block';
        this.confirmSendData = false;
      });
    }
  }

}
