import { Component, OnInit } from '@angular/core';
import { TranslateServiceLocal } from '../../../translate.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  providers: [ TranslateServiceLocal]
})
export class OverviewComponent implements OnInit {

  menu = 'Menu1';
  constructor(
    private translateService: TranslateServiceLocal,
  ) { }

  ngOnInit() {
  }

  changeRedirect(redirectTo) {
    this.translateService.changeRedirect(redirectTo);
  }
}
