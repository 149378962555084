import { Component, OnInit } from '@angular/core';
import { TranslateServiceLocal } from '../../translate.service';
// declare const google: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  providers: [TranslateServiceLocal]
})
export class ContactsComponent implements OnInit {
    focus1;
    focus2;
    focus3;
    focus4;
    focus5;

    constructor(private translateService: TranslateServiceLocal) {}

    ngOnInit() {}

    openWhatsapp(celular) {
      window.open('https://api.whatsapp.com/send?phone=57' + celular);
    }

}
